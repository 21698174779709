body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'UthmanicHafs1Ex1Ver12';
  src: local('UthmanicHafs1Ex1Ver12'), url(./Fonts/uthmanichafs1_ex1_ver12-webfont.woff2) format('woff2');
  src: local('UthmanicHafs1Ex1Ver12'), url(./Fonts/uthmanichafs1_ex1_ver12-webfont.woff) format('woff');
}

@font-face {
  font-family: 'me_quran';
  src: local('me_quran'), url(./Fonts/me_quran-webfont.woff2) format('woff2');
  src: local('me_quran'), url(./Fonts/me_quran-webfont.woff) format('woff');
}
